.site-breadcrumbs {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-breadcrumb-separator {
    margin: 0 10px;
  }
}
