.common-form-ooo {
  &__add-english {
    width: 100%;
    margin-bottom: 26px;
  }

  &__row {
    display: flex;

    margin-bottom: 20px;
  }

  &__inputs-grid {
    grid-template-columns: 1fr !important;
    margin-bottom: 10px;
  }

  &__field {
    margin-right: 15px;

    &_createDate,
    &_capital,
    &_ustav {
      width: 157px;
    }
  }

  &__eng-checkbox {
    display: none;
  }

  &__radio {
    margin-top: 17px;
  }

  &__checkbox {
    width: 100%;
    margin-bottom: 8px;

    .ant-checkbox-wrapper {
      font-size: 13px !important;
    }
  }

  &__ooo-input {
    .ant-input {
      color: var(--color-primary-blue) !important;
    }
  }
}
