.landing-footer {
  padding: 36px 0;
  color: var(--landing-light);

  background-color: var(--landing-dark);

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    margin: 0;
  }

  &__link {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
