.personal-account {
  &__bill-wrapper {
    display: flex;
  }

  &__control-item {
    margin-right: 12px;
  }

  &__bill-item {
    width: 120px;
  }

  &__set-bill-modal,
  &__bill-modal {
    top: 96px !important;
    max-width: 303px !important;
  }

  &__set-date-bill-save-btn {
    margin-top: 20px;
  }

  &__set-date-bill-title {
    font-family: "Gilroy", "Arial", sans-serif;
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
  }

  &__bill-success {
    display: inline-block;
    position: relative;
    margin-top: 22px;
    margin-bottom: 23px;
    padding-left: 26px;
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 18px;
    line-height: 1em;
    letter-spacing: 0.004em;
    font-weight: 800;
    color: var(--color-green2);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      background-image: url("./images/check-circle-icon.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  &__get-bill-button {
    margin-bottom: 20px;
  }

  &__input {
    input {
      padding-top: 4px;
      padding-bottom: 2px;
    }
  }

  &__modal-row {
    margin-bottom: 7px;

    display: flex;
  }

  &__modal-tabs {
    margin-left: auto;
  }

  &__radio {
    display: grid;
    grid-gap: 7px;
    margin-bottom: 7px;

    .ant-radio-wrapper {
      margin-right: 0;
    }
  }

  &__modal .ant-modal-body {
    padding-top: 50px !important;
  }

  &__modal-button {
    margin-top: 7px;
  }

  &__settings-buttons {
    display: flex;
    align-items: center;
  }

  &__icon {
    cursor: pointer;
    &_edit {
      margin-right: 5px;
    }
  }

  &__table {
    &_payments {
      tr td {
        &:last-child,
        &:nth-child(4) {
          padding-top: 0;
          padding-bottom: 0;

          svg {
            display: block;
          }
        }
      }
    }

    td a {
      color: var(--color-primary-blue);

      &:hover {
        color: #1f6cbf;
      }
    }
  }
}
