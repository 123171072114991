.site-tooltip {
  display: none;
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: -39px;
  left: 0;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid transparent;
    border-bottom: 4px solid #363636;
    top: -8px;
    left: 14px;
  }

  &_visible {
    display: block;
  }
}
