.marketplace-card {
  min-height: 250px;
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: var(--color-gray2);
  border-radius: 5px;

  &__logo {
    height: 40px;
    margin-bottom: 8px;
  }

  &__title {
    margin: 0;
    margin-bottom: 8px;

    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }

  &__description {
    flex-grow: 1;

    margin: 0;

    font-size: 12px;
    line-height: 20px;
  }

  &__bottom-wrapper {
    margin-top: 8px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__tags-list {
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
  }

  &__tags-item {
    padding: 6px 10px;
    margin-right: 8px;

    font-size: 12px;
    line-height: 1em;

    background-color: #ffffff;
    border-radius: 2px;

    &:last-child {
      margin-right: 0;
    }

    &_price {
      border: 1px solid #01b11a;
      background-color: transparent;
    }
  }
}
