.avatar-upload {
  $root: &;

  &__upload-wrapper {
    display: flex;
    align-items: center;
  }

  &__modal-preview {
    display: block;
    width: 100%;
    height: auto;
  }

  &__upload-title {
    font-size: 13px;
  }

  &__avatar-wrapper {
    flex-shrink: 0;
    position: relative;
    margin-right: 10px;
  }

  &__avatar-preview {
    user-select: none;

    &:hover + #{$root}__eye-icon {
      opacity: 0.9;
    }

    .ant-avatar-string {
      font-size: 24px;
      opacity: 1 !important;
      position: static !important;
    }
  }

  &__eye-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(#ffffff, 0.7);
    opacity: 0;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &__file-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__clear-button {
    cursor: pointer;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("./images/cross-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  &__upload-button {
    max-width: 176px;

    * {
      font: inherit;
    }
  }
}
