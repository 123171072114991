.company-registration-page {
  padding: 30px 0;

  &__wrapper {
    margin: 0 auto;
    max-width: 608px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin-bottom: 30px;

    img {
      display: block;
    }
  }

  &__form {
    padding: 24px 24px 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    background: #fff;
  }
}
