.hh-forms {
  font-size: 14px;
  line-height: 20px;

  &__title,
  &__subtitle,
  &__description {
    margin: 0;

    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
  }

  &__title {
    max-width: 380px;
    margin-bottom: 22px;

    font-size: 20px;
    line-height: 24px;
  }

  &__subtitle {
    margin-bottom: 10px;

    font-size: 18px;
    line-height: 21px;
  }

  &__description {
    margin-bottom: 22px;
    padding: 10px 24px 8px 64px;

    background-color: var(--color-gray2);
    background-image: url("images/logo.svg");
    background-repeat: no-repeat;
    background-size: 40px auto;
    background-position: 10px 12px;
    border-radius: 4px;
  }

  &__text-content {
    max-width: 550px;
  }

  &__list {
    margin: 0;
    margin-top: 4px;
    padding: 0;
    padding-left: 13px;
  }

  &__agreement-checkbox {
    margin-top: 15px;
  }

  &__agreement-text {
    margin-top: 5px;
    font-size: 11px;
    line-height: 15px;
  }
}
