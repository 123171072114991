.file-view {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px 18.75px;

  &__filename {
    color: var(--color-secondary);
    margin-left: 13.75px;
    margin-right: auto;
  }

  &__link {
    display: inline-flex;
  }

  &__icon {
    font-size: 22px;
    color: var(--color-primary-blue);

    &_download-icon {
      margin-left: 11.5px;
      color: #d9d9d9;

      &:hover {
        color: var(--color-primary-blue);
      }
    }
  }
}
