:root {
  --color-red: #dc1827;
  --color-red2: #a8071a;
  --color-orange: #e87d0b;
  --color-blue: #096dd9;
  --color-primary-blue: #0050b3;
  --color-secondary: #262626;
  --color-green: #389e0d;
  --color-green2: #237804;
  --color-green3: #2d7e0a;
  --color-gray: #e2e2e2;
  --color-gray2: #f4f4f4;
  --color-gray3: #f5f5f5;

  --landing-dark: #191919;
  --landing-neutral: #f3f3f3;
  --landing-neutral-light: #f9f9f9;
  --landing-light: #ffffff;
  --langing-secondary: #247fce;
}

.app {
}
