.systems-statuses {
  position: relative;

  &__status-item {
    position: relative;
    padding-left: 13px;
    margin-bottom: 2px;

    .site-tooltip {
      left: -14px;
      bottom: calc(100% + 4px);

      &::before {
        top: 100%;
        transform: rotate(180deg);
      }
    }
  }

  &__indicator {
    position: absolute;
    top: 6px;
    left: 0;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: var(--color-gray);

    &_ok {
      background-color: var(--color-green);
    }

    &_warning {
      background-color: var(--color-orange);
    }

    &_error {
      background-color: var(--color-red);
    }
  }

  &__text {
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 12px;
    line-height: 21px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}
