// переопределение глобальных стилей
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// вспомогательные бэм-блоки
.error-icon {
  font-size: 18px;
  color: var(--color-red);
}

.site-container {
  max-width: 1200px;
  width: 100%;
  padding-left: 40px;
}

.custom-button {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6px 18px 5px;
  min-height: 32px;
  border: none;

  font-family: "Gilroy", "Arial", sans-serif;
  line-height: 1em;
  font-weight: 600;
  color: #ffffff;

  border-radius: 15px;
  background-color: var(--color-secondary);

  transition: background-color 0.3s;

  .anticon {
    margin-right: 5px;

    line-height: 1em !important;
  }

  &:hover {
    color: #ffffff;
    background-color: var(--color-primary-blue);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: var(--color-secondary);
    }
  }

  &_long {
    width: 100%;

    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.08em;

    box-shadow: none;
    border-radius: 4px;
  }

  &_large {
    height: 40px;
  }

  &_faded {
    color: #000000;

    background-color: #f5f5f5;
    border-radius: 2px;

    &:hover {
      color: #000000;
      background-color: #f5f5f5;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.common-modal {
  max-width: 606px !important;
  width: 100% !important;

  // почему-то модалки, у которых нет шапки, смещены вниз
  top: 20px !important;

  .ant-modal-title {
    visibility: hidden;
  }
}

button.modal-save {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 9px;
  left: 9px;

  * {
    font-family: "Gilroy", "Arial", sans-serif;
  }
}

.entry-type {
  padding: 6px;
  border: 1px solid rgba(#000000, 0.1);

  font-size: 12px;
  color: #000000;
  white-space: nowrap;

  border-radius: 2px;
}

// што-то не знаю што
.sapUiTfInner.sapUiTf {
  font-size: 16px !important;
}

.sapUiLbxITxt {
  font-size: 16px !important;
}

// antd
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 6px;
}

.ant-descriptions-header {
  margin-bottom: 5px;
}

.ant-table-body {
  overflow: hidden !important;
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  line-height: 1em !important;

  .anticon {
    margin-right: 5px;
  }
}

.ant-btn > .anticon + span {
  margin-left: 0 !important;
}

.ant-pagination-item {
  border: none !important;

  a:hover {
    color: #000000 !important;
    // временно :(
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  cursor: pointer;

  border: none !important;
  border-radius: 50% !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.06), 0 0 8px rgba(0, 0, 0, 0.12);

  &:hover {
    .anticon {
      // color: #0050b3;
      color: #000000;
    }
  }

  .anticon {
    color: #000000;
    transition: color 0.3s;
  }
}

.ant-pagination-disabled {
  opacity: 0;
  cursor: default !important;

  .ant-pagination-item-link {
    cursor: default !important;
  }
}

.ant-pagination-jump-next .ant-pagination-item-container,
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-item a {
  font-family: "Gilroy", "Arial", sans-serif;
  line-height: 32px;
  font-weight: 600;

  transition: color 0.3s !important;

  .anticon {
    color: #000000 !important;
    // временно х_х
  }
}

.ant-pagination-item-ellipsis {
  color: #000000 !important;
}

.ant-pagination-item-active {
  cursor: default !important;
}

.ant-pagination-item-active a {
  user-select: none;
  cursor: default !important;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #ffffff !important;

  border-radius: 50%;
  background-color: #070707;

  &:hover {
    color: #ffffff !important;
  }
}

.ant-input,
.ant-input-number {
  font-size: 16px !important;

  box-shadow: none !important;
  border-color: #0000001f !important;
}

.ant-input-lg {
  padding-top: 8px !important;
  padding-bottom: 6px !important;
}

.ant-input-affix-wrapper {
  padding-top: 7px;
  padding-bottom: 7px;

  .ant-input-lg {
    padding: 0 !important;
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.ant-radio-button-wrapper:first-child {
  border-right-width: 0 !important;
  border-radius: 15px 0 0 15px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 15px 15px 0 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.ant-radio-wrapper,
.ant-radio-button-wrapper {
  font-size: 13px !important;
}

.ant-radio-disabled,
.ant-radio-wrapper-disabled {
  cursor: not-allowed !important;
}

.ant-radio-disabled + span,
.ant-checkbox-disabled + span,
.ant-select-disabled .ant-select-selector {
  color: #000000 !important;
}

.ant-checkbox-inner,
.ant-radio-inner {
  // .ant-upload
  border-color: #0000001f !important;
}

.ant-select-selector,
.ant-select-selection-search-input,
.ant-picker,
.ant-input-affix-wrapper {
  box-shadow: none !important;
  border-color: #0000001f !important;
}

.ant-select-item-option-selected {
  font-weight: 400 !important;
}

.ant-tabs-tab {
  background-color: var(--color-gray2) !important;
  border: 1px solid var(--color-gray) !important;
  color: #595959;
  border-radius: 2px 2px 0 0 !important;

  &-active {
    filter: grayscale(0);
    border-bottom: 0 !important;
  }

  &-disabled {
    color: rgba(#595959, 0.9) !important;
    .ant-tabs-tab-btn:active {
      color: rgba(#595959, 0.9) !important;
    }
  }

  &:hover {
    color: rgba(#595959, 0.9) !important;
  }
}

.ant-tabs-tab-active {
  border-bottom-color: var(--color-gray) !important;
  color: #000000 !important;
  background-color: #fff !important;

  .site-page__tab-value {
    color: #0000004d;
  }
}

.ant-tabs-nav {
  margin-bottom: 0 !important;

  &::before {
    border-bottom-color: var(--color-gray) !important;
  }
}

.ant-table thead > tr > th {
  font-weight: 500 !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100% !important;
}

.ant-menu-sub .ant-menu-item {
  padding-left: 38px !important;
}

.ant-menu-item-selected {
  background-color: #e6f7ff !important;
}

.ant-upload-list-item-removed {
  display: none !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400;
  background-color: #ffffff;

  &:hover {
    background-color: #f5f5f5;
  }
}

.ant-table tbody > tr.ant-table-row-selected > td {
  background-color: #fafafa !important;
}

.ant-modal-content {
  border-radius: 10px !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) !important;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0 !important;
}

.ant-modal-close {
  width: 56px;
  height: 56px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 2px;
    background-color: #262626;
    border-radius: 10px;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .ant-modal-close-x {
    display: none;
  }
}

.ant-modal-body {
  padding: 16px 20px 20px 20px !important;
}

// intercom
.intercom-namespace .intercom-yzs3l {
  z-index: 10 !important;
}

.sticky-tooltip-wrapper {
  z-index: 1;

  .site-tooltip {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;

    &:before {
      content: none;
    }
  }
}

.sticky-tooltip {
  min-width: 200px !important;
  max-width: 200px !important;
  white-space: normal !important;

  &::before {
    content: none !important;
  }
}
