.changeOOO-page {
  .change-common-form-ooo__radio {
    padding-top: 1px;
  }

  .applicant-form__user-preview {
    margin-bottom: 0;
  }

  .applicant-form__row_inputs {
    margin-bottom: 0;
    margin-top: -1px;
  }

  .change-common-form-ooo__inputs-grid {
    margin-top: 7px;
  }

  .activity-form__OKVED-btn {
    margin-top: 18px;
  }
}
