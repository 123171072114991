.bubble-text {
  position: relative;

  display: inline-block;

  padding: 16px 30px 14px;

  font-family: "IBM Plex Sans", "Arial", sans-serif;
  line-height: 21px;
  color: var(--landing-light);

  background-color: var(--landing-dark);
  border-radius: 40px;

  &::after {
    content: "";

    position: absolute;
    top: calc(100% - 2px);
    left: 30px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 9px 0 9px;
    border-color: var(--landing-dark) transparent transparent transparent;
  }

  &_position_top-left {
    &::after {
      top: auto;
      bottom: calc(100% - 2px);
      transform: rotate(180deg);
    }
  }

  &_position_top-right {
    &::after {
      top: auto;
      bottom: calc(100% - 2px);
      left: auto;
      right: 30px;
      transform: rotate(180deg);
    }
  }

  &_position_bottom-right {
    &::after {
      left: auto;
      right: 30px;
    }
  }

  &_large {
    width: 100%;
    padding: 26px 46px 22px 36px;
    border-radius: 60px;

    &::after {
      left: 50px;
    }
  }

  &__author {
    margin: 12px 0 0;
    padding-left: 40px;

    line-height: 1em;
    font-weight: 600;
  }
}
