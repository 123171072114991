.signing-data {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: grid;
  grid-gap: 10px;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &_secondary {
      font-weight: 400;
    }
  }

  &__divider {
    margin: 0 !important;
  }

  &__text {
    &_small {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__text-wrapper {
    display: grid;
    grid-gap: 5px;
  }
}
