.change-common-form-ooo {
  &__row {
    display: flex;
  }

  &__inputs-grid {
    margin: 5px 0;
  }

  &__field {
    &_w33 {
      grid-column: 0 / 1;
    }

    &_ustav {
      width: 157px;
      margin-top: -1px;
    }
  }

  &__radio-outer-wrapper,
  &__additional-fields {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  &__radio-outer-wrapper {
    margin-top: 13px;
    margin-bottom: 1px;
  }

  &__radio-inner-wrapper {
    .ant-radio-wrapper {
      display: block;
      margin-bottom: 7px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .site-radio__error {
      display: none;
    }
  }

  &__ooo-input,
  &__ooo-checkbox {
    grid-column-start: 1;
    grid-column-end: 4;
    .ant-input {
      color: #000 !important;
    }
  }

  &__ooo-checkbox {
    font-size: 13px;
    padding-top: 9px;
  }
}
