.password-view {
  display: flex;
  align-items: center;

  &__text-content {
    display: inline-block;

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;

    &_blurred {
      filter: blur(3px);
    }
  }

  &__button {
    cursor: pointer;

    margin-left: 4px;
    padding: 0 4px;
    border: none;

    transition: color 0.3s;

    background-color: transparent;

    &:hover {
      color: var(--color-primary-blue);
    }

    &:focus {
      outline: none;
    }

    .anticon {
      margin-right: 1px;
    }
  }
}
