.site-navmenu {
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--color-gray);

  background-color: var(--color-gray2);

  &__logo-link {
    margin-left: 24px;
    margin-top: 23px;
    margin-bottom: 22px;

    display: inline-block;

    img {
      display: block;
    }
  }

  &__menu-wrapper {
    // height: calc(100% - 62px);
    border: none;
  }

  &__footer-item {
    display: none; // временно!
    margin-top: auto !important;
  }

  .ant-menu {
    background-color: var(--color-gray2);
  }

  .ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  .ant-menu-item::after {
    border: none;
  }

  .ant-menu-submenu-arrow {
    top: calc(50% + 2px) !important;

    &::before,
    &::after {
      width: 8px !important;
    }

    &::before {
      transform: rotate(-48deg) translateX(3px) !important;
    }

    &::after {
      transform: rotate(48deg) translateX(-3px) !important;
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-arrow {
      transform: translateY(-4px) !important;

      &::before {
        transform: rotate(48deg) translate(3px, -0.5px) !important;
      }

      &::after {
        transform: rotate(-48deg) translate(-3px, -0.5px) !important;
      }
    }
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
    * {
      font-family: "Gilroy", "Arial", sans-serif;
      font-weight: 600;
    }
  }

  .ant-menu-submenu-selected {
    color: #000 !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    margin: 0;
    margin-bottom: 0 !important;
  }

  &__settings-modal {
    width: 328px !important;
  }

  &__statuses-block {
    margin-top: auto;
    padding-left: 24px;
    padding-bottom: 16px;
  }

  &__social-icon {
    display: block;
    margin: auto 0 21px 24px;
    width: 25px;
    height: 25px;
    background: #909090 url(./assets/telegram.svg) no-repeat center / cover;
    border-radius: 100%;

    &:hover {
      background: url(./assets/telegram-hover.svg) no-repeat center / cover;
    }
  }
}
