.city-label {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  white-space: nowrap;

  &_orange {
    color: var(--color-orange);
    background-color: #fff7e6;
  }

  &_blue {
    color: var(--color-blue);
    background-color: #f0f5ff;
  }

  &_green {
    color: var(--color-green);
    background-color: #ecfbdf;
  }
}
