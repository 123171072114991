.delivery-form {
  &__address {
    margin-top: 20px;
    margin-bottom: 7px;
  }

  &__error-text,
  &__success-text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
  }

  &__error-text {
    color: var(--color-primary-blue);
  }

  &__success-text {
    color: var(--color-green);
  }

  textarea {
    resize: none;
    height: 80px;
  }
}
