.business-search {
  $root: &;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__divider {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  &__search-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__search-info {
    max-width: 120px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 12px;
    line-height: 16px;
    margin-left: 14px;
    max-height: 32px;
  }

  &__search {
    min-width: 510px;
    margin-right: 0;
    input {
      font-size: 14px !important;
    }
  }

  &__empty-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #{$root}__search-wrapper {
      width: auto;
    }
  }

  &__empty-description {
    margin: 12px 0;
    max-width: 510px;
    text-align: center;
    line-height: 20px;
  }

  &__images-wrapper {
    display: flex;
    img {
      display: block;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__clear-icon {
    cursor: pointer;

    &:hover path {
      fill-opacity: 0.3;
    }

    path {
      transition: fill-opacity 0.3s;
    }
  }

  &__content {
    margin-top: 25px;
  }

  &__main-info,
  &__full-info {
    display: flex;
  }

  &__column {
    width: 50%;
    padding-right: 80px;
  }

  &__company-shortname,
  &__company-fullname,
  &__subtitle {
    font-family: "Gilroy", "Arial", sans-serif;
  }

  &__company-shortname {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
  }

  &__company-fullname {
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
  }

  &__company-status {
    margin-top: 5px;
    font-weight: 500;
    line-height: 20px;
    &_green {
      color: var(--color-green);
    }
    &_orange {
      color: var(--color-orange);
    }
    &_red {
      color: var(--color-red);
    }
  }

  &__company-size {
    margin-top: 5px;
    line-height: 22px;
  }

  &__section {
    margin-bottom: 20px;
  }

  &__subtitle {
    margin-bottom: 5px;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
  }

  &__name {
    text-transform: capitalize;
  }

  &__address-link {
    vertical-align: top;
    display: inline-block;

    svg {
      display: block;
    }
  }

  &__table-cell {
    vertical-align: top;
    padding-right: 10px;
    &_name {
      max-width: 50%;
    }
  }

  &__info-block {
    margin-top: 15px;
  }

  &__description {
    font-size: 11px;
    line-height: 15px;
    max-width: 210px;
    margin-top: 2px;
  }

  &__text {
    line-height: 20px;
    &_with-margin {
      margin-top: 3px;
    }
  }

  &__okveds-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 4px 8px;
  }

  &__okved {
    line-height: 18px;
    &_main {
      font-weight: 500;
    }
  }
}
