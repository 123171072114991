.site-page {
  width: 100%;

  &__content {
    background: #fff;
    padding: 22px 30px 47px 30px;
    max-width: 1240px;
    min-height: calc(100vh - 63px);
    display: flex;
    flex-direction: column;
  }

  &__tabs {
    position: relative;
    padding-left: 30px;
    padding-top: 23px;
    background-color: var(--color-gray2);

    &::before {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      width: 40px;
      height: 1px;

      background-color: var(--color-gray);
    }
  }

  &__tab-wrapper {
    display: flex;
    align-items: center;
  }

  &__tab-value {
    margin-left: 4px;

    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
    color: rgba(#000000, 0.25);
  }

  &__tab-name {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control-items {
    display: flex;
  }

  &__control {
    margin: 0 2px;

    &_last {
      margin-right: 0;
    }

    &.ant-btn-icon-only {
      width: auto;
      padding: 0 8px;
      border: 1px solid #d9d9d9;

      border-radius: 2px;
      box-shadow: none;

      .anticon {
        margin-right: 0;

        svg {
          display: block;
        }
      }
    }
  }

  &__search {
    max-width: 363px;
    width: 100%;
    padding-left: 28px;
    margin-right: 12px;

    font-size: 14px !important;

    background-image: url("./images/search-icon.svg");
    background-repeat: no-repeat;
    background-size: 12.6px auto;
    background-position: 9px center;
  }

  &__controls-divider {
    width: 1px;
    background-color: #e8e8e8;
    margin: 0 2px;
  }

  &__button-filter {
    margin-right: 6px;
  }

  &__modal {
    max-width: 606px !important;
    width: 100% !important;

    .ant-modal-title {
      visibility: hidden;
    }
  }

  &__save {
    color: #000;
    font-weight: 500;
    position: absolute;
    top: 9px;
    left: 9px;
  }

  &__table {
    margin-top: 15px;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
    }

    .ant-table-header {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .ant-table-thead > tr:first-child > th:first-child,
    .ant-table-thead > tr:first-child > th:last-child {
      border-radius: 0;
    }

    td {
      white-space: nowrap !important;

      a {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        padding: 16px;

        color: inherit;
      }
    }

    .ant-pagination.ant-table-pagination {
      float: none;

      margin: 35px auto 0;
    }
  }

  &__list {
    margin: 15px 0 0;
    padding: 0;

    list-style: none;
  }

  &__title-wrapper {
    margin: 0;
    padding: 23px 40px;
    background-color: var(--color-gray2);
  }

  &__title-text {
    margin: 0;

    font-family: "Gilroy", "Arial", sans-serif;
    color: #262626;
    font-size: 30px;
    font-weight: 800;
  }

  .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
    margin: 0;
  }

  .ant-skeleton-content {
    display: flex;
    align-items: center;
    height: 16px;
  }

  .ant-skeleton-title {
    width: 100% !important;
    margin: 0 !important;
  }
}
