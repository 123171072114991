.site-autocomplete {
  position: relative;

  &__highlight {
    color: var(--color-blue);
  }

  &__selected-item {
    background-color: #f5f5f5 !important;
  }

  .ant-select {
    width: 100%;
    font-size: 16px !important;
  }

  input {
    color: #000000 !important;
  }

  &__title {
    margin-bottom: 2px;
    color: #000000;
    font-size: 13px;
  }

  &_red {
    .ant-select-selector {
      border-color: var(--color-red) !important;
    }
  }

  &_orange {
    .ant-select-selector {
      border-color: var(--color-orange) !important;
    }
  }
}
