.user-preview {
  position: relative;

  display: flex;
  align-items: center;

  background-color: #ffffff;
  border-radius: 4px;

  &__info-wrapper {
    flex-grow: 1;

    cursor: pointer;

    padding: 12px 14px 10px 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 48px;

    display: flex;
    align-items: center;

    border-radius: 4px;

    font-weight: 600;
    font-size: 16px;
    color: #262626;

    * {
      font-family: "Gilroy", "Arial", sans-serif;
    }
  }

  &__options-list {
    margin: 0;
    margin-left: 8px;
    padding: 0;

    list-style: none;

    display: flex;
  }

  &__options-item {
    position: relative;

    padding-left: 9px;
    margin-right: 8px;

    &::before {
      content: "";

      position: absolute;
      left: 0;
      top: 50%;

      transform: translateY(-10px);

      width: 1px;
      height: 18px;

      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &__remove-btn {
    cursor: pointer;

    margin-left: 9px;
    padding: 0;
    border: none;

    background-color: transparent;

    &:focus {
      outline: none;
    }

    svg {
      display: block;
    }
  }

  &__error {
    color: var(--color-red);
  }

  &__tooltip.site-tooltip {
    top: -40px;
    bottom: auto;

    &::before {
      left: 14px;
      top: auto;
      bottom: -8px;

      transform: rotate(180deg);
    }
  }
}
