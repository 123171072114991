.site-input {
  position: relative;

  &__title {
    margin-bottom: 2px;
    color: #000000;
    font-size: 13px;
  }

  &_red {
    input {
      border-color: var(--color-red) !important;
    }
  }

  &_orange {
    input {
      border-color: var(--color-orange) !important;
    }
  }
}
