.site-steps {
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--color-secondary);
    border: none !important;
  }

  .ant-steps-item-content {
    min-height: 10px;
    transform: translateY(1px);
  }

  .ant-steps-item {
    padding-bottom: 7px;

    * {
      font-family: "Gilroy", "Arial", sans-serif;
      font-weight: 600;
      font-size: 14px;
    }

    .ant-steps-item-icon {
      border: none;
      margin-right: 8px;
      width: 24px;
      height: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      .ant-steps-icon {
        margin-top: 3px;
      }
    }
  }

  &__last-step .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: rgba(0, 0, 0, 0.1);

    .ant-steps-icon {
      color: #ffffff;

      transform: translate(-1px, 2px);

      svg {
        transform: translateY(-2px);

        width: 10px;
        height: auto;
      }
    }
  }

  &__last-step .ant-steps-item-icon {
    background-color: var(--color-green) !important;

    .ant-steps-icon {
      transform: translate(-1px, 2px) !important;
    }
  }

  &__loading-step .ant-steps-item-icon {
    background-color: transparent !important;
  }

  &__loader-icon {
    svg {
      font-size: 22px !important;
      margin-left: -2px;
      margin-top: 4px;
      color: var(--color-secondary);
    }
  }

  &__failed-step {
    .ant-steps-item-description {
      div {
        font-size: 13px;
      }
    }

    .ant-steps-item-icon {
      background: transparent !important;
    }

    .anticon {
      position: relative;
      top: 2px;
      left: -0.5px;
    }
    svg {
      color: red;
      width: 24px;
      height: 24px;
    }

    &_warning {
      svg {
        color: #fec218;
        width: 24px;
        height: 24px;
      }
    }
  }

  .ant-steps-item-subtitle {
    margin-left: 0;
  }

  .ant-steps-item-title {
    display: flex;
    flex-direction: column;

    height: 22px;
  }

  .ant-steps-item-tail::after {
    background-color: var(--color-secondary);
    width: 2px !important;
    transform: translateX(-1px);
  }

  .ant-steps-item-active button {
    margin-top: 11px;
  }

  .ant-steps-item-active .ant-steps-item-tail::after {
    background-color: var(--color-secondary);
  }

  .ant-steps-item-description {
    padding-bottom: 26px !important;
  }
}
