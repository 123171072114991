.crt-check-page {
  $root: &;

  &__content {
    align-items: stretch;
    justify-content: flex-end;
  }

  &__container {
    background-image: linear-gradient(to right, transparent 47%, #f8f8f8 47%);
  }

  &__form-wrapper {
    width: 100%;
  }

  &__inputs-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 7px;
    align-items: center;

    &_two-columns {
      display: block;
      column-count: 2;
      column-fill: balance;

      #{$root}__info-wrapper {
        padding-top: 7px;
      }
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  &__detailed-info-btn {
    margin-top: 25px;
  }

  &__input {
    max-width: 200px;
    display: inline-block;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__success-icon {
    margin-right: 6px;
  }

  &__info-wrapper {
    padding-top: 22px;
  }

  &__submit-button {
    margin-top: 19px;
  }

  &__info-text {
    white-space: nowrap;
    &_with-dot:after {
      content: "·";
      margin: 0 3px;
      color: #000;
    }
  }

  &__download-icon {
    margin-right: 8px;
  }

  &__additional-crt-data {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__pdf-link {
    display: flex;
    align-items: center;
  }

  &__status {
    color: var(--color-red);
    display: flex;
    align-items: center;

    &:not(:last-child) {
      &:after {
        content: "·";
        margin: 0 3px;
        color: #000;
      }
    }

    &_success {
      color: var(--color-green);
    }
  }

  &__detailed-info {
    break-inside: avoid;
  }
}
