body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray2) !important;
}

*,
::after,
::before {
  box-sizing: border-box;
  font-family: "Roboto", "Arial", sans-serif;
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;

  src: url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;

  src: url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  font-display: swap;

  src: url("./assets/fonts/Gilroy-Regular.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Regular.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  font-display: swap;

  src: url("./assets/fonts/Gilroy-Medium.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  font-display: swap;

  src: url("./assets/fonts/Gilroy-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  font-display: swap;

  src: url("./assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-ExtraBold.woff") format("woff");
}
