.file-upload {
  position: relative;
  display: inline-block;
  //height: 143px;

  &_small {
    height: auto;
  }

  &__label {
    position: absolute;
    left: 50%;
    bottom: 18px;
    transform: translate(-50%, 0);
    z-index: 10;

    width: 115px;

    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
    line-height: 22px;
    white-space: nowrap;

    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #000;
  }

  &__download-icon {
    cursor: pointer;
    margin-left: 5px;
  }

  &__icon {
    display: block;

    width: 34px;
    height: auto;
    margin: 0 auto;
  }

  &__tooltip.site-tooltip {
    min-width: 200px;
    max-width: 200px;
    white-space: normal;

    &::before {
      content: none;
    }
  }

  .ant-upload-animate-inline-enter {
    animation: none !important;
  }

  .ant-upload-text {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
  }

  .ant-upload.ant-upload-drag {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.18);
    background-color: transparent;
  }

  .ant-upload.ant-upload-btn {
    padding-top: 21px;
    padding-bottom: 19px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 13px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin-bottom: 4px;
  }

  .avatar-uploader > .ant-upload {
    width: 142px;
  }

  &.uploaded .ant-upload-list.ant-upload-list-picture-card {
    width: 100%;
    height: 100%;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card,
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: none !important;
  }

  // .ant-upload-list-picture-card-container,
  // .ant-upload-animate-inline-enter {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
    margin: 0;
  }

  .ant-upload-list-item-thumbnail {
    object-fit: contain !important;
  }

  .ant-upload-list-picture-card .ant-upload-list {
    height: 40px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    line-height: 1.3em;
    padding: 0 14px !important;
  }

  .ant-upload-list-item {
    border-radius: 4px !important;
    transform: translateY(-0.5px);
  }
}
