.service-block {
  position: relative;

  display: inline-block;

  padding: 18px 30px;
  padding-left: 60px;
  margin: 0;

  font-size: 14px;
  line-height: 1em;
  font-weight: 600;
  color: var(--landing-dark);
  white-space: nowrap;
  letter-spacing: 0.2px;

  background-color: var(--landing-light);
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
  border-radius: 40px;

  &::before {
    content: "";

    position: absolute;
    top: 50%;
    left: 8px;

    transform: translateY(-50%);

    width: 35px;
    height: 35px;

    background-color: var(--landing-neutral-light);
    background-image: url("../../assets/plus.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px auto;
    border-radius: 50%;
  }

  &_checked {
    &::before {
      background-image: none;
    }

    &::after {
      content: "";

      position: absolute;
      top: 50%;
      left: 18px;

      transform: translateY(-70%);

      width: 27px;
      height: 28px;

      background-image: url("../../assets/check.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &_empty {
    padding-left: 30px;

    &::before,
    &::after {
      content: none;
    }
  }
}
