.foreign-form {
  &__getSource {
    grid-column: 2 / 4;
  }

  .site-form__radio {
    margin-bottom: 22px;
  }

  &__radio-column {
    display: flex;
  }

  &__validity-wrapper {
    grid-column: span 2;
    display: flex;
    align-items: flex-end;
  }

  &__validity-field {
    max-width: 176px;
    margin-right: 10px;
  }

  &__infinite-checkbox {
    margin-bottom: 9px;
  }
}
