.registration-form {
  &__inputs-grid {
    align-items: end;
  }

  &__address {
    margin-bottom: 7px;
  }

  &__address-suffix_icon_cross {
    cursor: pointer;
  }

  &__address-tooltip.site-tooltip {
    bottom: calc(100% + 4px);
    left: auto;
    right: 0;

    &::before {
      top: 100%;
      left: auto;
      right: 14px;
      transform: rotate(180deg);
    }
  }

  &__districtName {
    grid-column: 2 / 4;
  }

  &__localityName {
    grid-column: 2 / 4;
  }

  &__streetName {
    grid-column: 2 / 4;
  }

  &__final-address-wrapper {
    margin-bottom: 7px;
  }

  &__final-address-input {
    display: none;
  }

  &__address-for-docs {
    cursor: pointer;
    display: inline-block;
    margin-top: 8px;
    padding: 1px 8px;
    font-size: 16px;
    line-height: 24px;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 2px;
  }

  &__long-field {
    grid-column: 1 / 4;

    &_without-label {
      margin-top: 6px;
    }
  }

  &__faded-field {
    .ant-select-selector {
      background-color: rgba(#000000, 0.03) !important;
    }
  }

  &__inputs-set-title {
    font-size: 13px;
    color: #262626;
  }

  &__inputs-set-wrapper {
    display: flex;
  }

  &__view-input,
  &__name-input {
    &::before {
      position: absolute;
      top: calc(50% + 2px);
      left: 12px;
      z-index: 2;
      transform: translateY(-50%);
    }
  }

  &__view-input::before,
  &__name-input::before,
  &__type-input .ant-input::placeholder,
  &__type-input .ant-select-selection-placeholder,
  &__number-input .ant-input::placeholder {
    font-size: 12px;
    line-height: 20px;
    color: #bebebe;
    opacity: 1;
  }

  &__view-input,
  &__type-input {
    .ant-input,
    .ant-select-selector {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none !important;
    }
  }

  &__name-input,
  &__number-input {
    .ant-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__view-input {
    max-width: 100px;

    &::before {
      content: "вид";
    }

    .ant-input {
      padding-left: 42px;
    }
  }

  &__name-input {
    width: 100%;

    &::before {
      content: "наим.";
    }

    .ant-input {
      padding-left: 53px;
    }
  }

  &__type-input {
    width: 100%;
    max-width: 77px;

    .ant-select-selection-placeholder {
      transform: translateY(2px);
    }
  }
}
