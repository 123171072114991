.login-form {
  &__title {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 800;
    font-size: 24px;
    margin: 0;
    margin-bottom: 12px;
  }

  &__input {
    margin-bottom: 7px;
  }

  &__button {
    margin-top: 19px;
  }
}
