.formed-docs {
  &__title {
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 20px;
    line-height: 25px;
    font-weight: 800;
  }

  &__list {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    margin-bottom: 8px;

    // font-weight: 600;
    line-height: 22px;
    color: #000000;
  }

  &__link {
    color: inherit;

    display: flex;
    align-items: flex-start;

    &:hover {
      .formed-docs__file-icon path {
        fill: #2287d4;
      }
    }
  }

  &__file-icon {
    display: block;

    width: 13px;
    height: auto;

    path {
      transition: fill 0.3s;
    }
  }

  &__file-name {
    margin-left: 5px;
  }

  &__divider {
    margin: 12px 0;

    background-color: rgba(0, 0, 0, 0.1);
  }
}
