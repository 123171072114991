.new-request {
  $root: &;

  * {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 800;
  }

  &__title {
    padding-bottom: 13px;
    border-bottom: 1px solid #e2e2e2;
    font-size: 20px;
    line-height: 26px;
  }

  &__content-wrapper {
    padding-top: 20px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    font-size: 16px;
    line-height: 20px;
  }

  &__item {
    border-radius: 20px;
    position: relative;

    &_disabled {
      #{$root}__link-wrapper {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }
  }

  //TODO: удалить, когда уберу тултип "Временно недоступно"
  &__tooltip-wrapper {
    z-index: 1;

    .site-tooltip {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 14px;

      &:before {
        content: none;
      }
    }
  }

  &__link-wrapper {
    display: flex;
    justify-content: center;
    padding: 160px 0 21px;
    color: inherit;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: auto 144px;
    background-position: top 13px center;
    transition: background-color 0.3s;

    &_ip-reg {
      background-image: url("./images/ip-reg-bg.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./images/ip-reg-bg@2x.png");
      }
    }

    &_ip-change {
      background-image: url("./images/ip-change-bg.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./images/ip-change-bg@2x.png");
      }
    }

    &_ip-close {
      background-image: url("./images/ip-close-bg.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./images/ip-close-bg@2x.png");
      }
    }

    &_ooo-reg {
      background-image: url("./images/ooo-reg-bg.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./images/ooo-reg-bg@2x.png");
      }
    }

    &_ooo-change {
      background-image: url("./images/ooo-change-bg.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./images/ooo-change-bg@2x.png");
      }
    }

    &:hover {
      color: inherit;
      background-color: #f6f6f6;

      #{$root}__type {
        color: #ffffff;
        background-color: #262626;
      }
    }
  }

  &__type {
    margin-left: 2px;
    padding: 6px 10px 3px;
    font-size: 14px;
    font-weight: 600;
    background-color: #e2e2e2;
    border-radius: 20px;
    transition-property: color, background-color;
    transition-duration: 0.3s;
  }
}
