.landing-header {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 2;

  width: 100%;
  padding: 15px 0;

  background-color: transparent;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav-wrapper {
    display: inline-block;

    padding: 0 30px;

    background-color: var(--landing-neutral-light);
    border-radius: 40px;
  }

  &__nav-list {
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
  }

  &__nav-item {
    margin-left: 40px;

    line-height: 1em;
    font-weight: 600;
    letter-spacing: 0.2px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__nav-link {
    position: relative;

    display: block;

    padding: 16px 0;

    color: inherit;

    transition: color 0.25s;

    &:hover {
      color: var(--langing-secondary);
    }
  }
}
