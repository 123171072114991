.login-page {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  &__container {
    position: relative;
  }

  &__form-wrapper {
    width: 300px;
    flex-direction: column;
  }

  &__logo {
    position: absolute;
    top: -46px;
    left: 50%;

    transform: translateX(-50%);

    img {
      display: block;
    }
  }
}
