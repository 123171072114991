.company-settings-form {
  &__common-inputs {
    grid-template-columns: 1fr;
  }

  &__requisites-inputs {
    margin-bottom: 7px;

    grid-template-columns: 1fr 1fr;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__icon-label {
    padding: 6.5px 0;
    margin-right: 12px;

    font-size: 13px;
    line-height: 19.5px;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__activation-button,
  &__key-input {
    margin-left: 24px;
  }

  &__key-input {
    width: 100%;
  }

  &__submit-button {
    margin-top: 24px;
  }
}
