.status {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px;
  white-space: nowrap;

  &_orange {
    color: var(--color-orange);
    background-color: #fff7e6;
  }

  &_red {
    color: var(--color-red);
    background-color: #fff1f0;
  }

  &_blue {
    color: var(--color-blue);
    background-color: #f0f5ff;
  }

  &_green {
    color: var(--color-green);
    background-color: #ecfbdf;
  }

  &_gray {
    color: #000000;
    background-color: #f3f3f3;
  }
}
