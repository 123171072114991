.site-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #ffffff80;

  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 50px;
    background: url("./images/buro.svg") no-repeat center / contain;

    &_fixed {
      position: fixed;
    }
  }
}
