.companies-table {
  &__search-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    margin-right: 20px;
  }

  &__modal-title {
    margin: 0;
    max-width: 380px;
    margin-bottom: 22px;

    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  &__star-filter {
    margin-right: 10px;
    cursor: pointer;
  }

  &__control-items {
    align-items: center;
  }

  .ant-table tbody > tr {
    cursor: pointer;
  }
}
