.landing-page {
  overflow-x: hidden;
  overflow-y: visible;
  scroll-behavior: smooth;

  min-width: 1024px;
  max-height: 100vh;
  padding-top: 100px;

  font-size: 16px;
  line-height: 25px;
  font-weight: 400;

  background-color: var(--landing-neutral-light);

  // @media (max-width: 1024px) {
  //   width: 100vw;
  //   max-height: 100%;
  // }

  * {
    font-family: "Gilroy", "Arial", sans-serif;
  }

  &__top-wrapper {
    position: relative;

    width: 100%;
    min-height: 760px;

    background-image: url("./assets/bg-top@1x.png");
    background-size: 1340px auto;
    background-repeat: no-repeat;
    background-position: top center;

    @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
      background-image: url("./assets/bg-top@2x.png");
    }
  }

  &__human-container {
    position: absolute;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &_left {
      bottom: 10%;
      left: 0;

      width: 438px;
      height: 473px;

      background-image: url("./assets/top-human-1@1x.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./assets/top-human-1@2x.png");
      }

      .landing-page__bubble-text {
        bottom: 100%;
        left: 10px;
      }
    }

    &_center {
      top: 17%;
      right: 27%;

      width: 310px;
      height: 280px;

      background-image: url("./assets/top-human-2@1x.png");
      background-size: 295px auto;
      background-position: bottom center;

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./assets/top-human-2@2x.png");
      }

      .landing-page__bubble-text {
        bottom: 100%;
        left: 56px;
      }
    }

    &_right {
      right: 0;
      bottom: 8%;

      width: 532px;
      height: 398px;

      background-image: url("./assets/top-human-3@1x.png");

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./assets/top-human-3@2x.png");
      }

      .landing-page__bubble-text {
        bottom: 104%;
        right: 10px;
      }
    }
  }

  &__bubble-text {
    position: absolute;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title,
  &__subtitle {
    margin: 0 auto;
    max-width: 960px;

    font-weight: 800;
    color: #000000;
    letter-spacing: -0.75px;
    text-align: center;
  }

  &__title {
    margin-bottom: 30px;

    font-size: 46px;
    line-height: 58px;
  }

  &__subtitle {
    font-size: 42px;
    line-height: 58px;

    &_left {
      text-align: left;
    }
  }

  &__section {
    margin-top: 120px;

    &_services {
      padding-bottom: 120px;
    }

    &_how-it-works {
      margin: 0;
      background-color: var(--landing-neutral);

      .how-it-works-container {
        padding-top: 240px;
        padding-bottom: 240px;

        background-image: url("./assets/bg-middle@1x.png");
        background-size: 640px 739px;
        background-repeat: no-repeat;
        background-position: bottom -48px left;

        @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
          background-image: url("./assets/bg-middle@2x.png");
        }

        @media (max-width: 1270px) {
          background-size: 520px auto;
          background-position: bottom -4px left;
        }
      }
    }

    &_features {
      margin-top: 80px;
      padding: 50px;

      background-color: var(--landing-neutral);
      background-image: url("./assets/bg-inner@1x.png");
      background-repeat: no-repeat;
      background-size: 534px 356px;
      background-position: left 50px center;
      border-radius: 40px;

      @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
        background-image: url("./assets/bg-inner@2x.png");
      }

      @media (max-width: 1270px) {
        background-size: 460px auto;
      }

      .landing-page__list {
        margin-top: 36px;
      }
    }

    &_price {
      background-color: var(--landing-neutral);

      .price-container {
        padding: 160px 0;

        text-align: center;

        background-image: url("./assets/board@1x.png"), url("./assets/plant@1x.png");
        background-repeat: no-repeat;
        background-position:
          left 120px bottom 40px,
          right 160px bottom 30px;
        background-size:
          240px 343px,
          160px 284px;

        @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
          background-image: url("./assets/board@2x.png"), url("./assets/plant@2x.png");
        }

        @media (max-width: 1270px) {
          background-position:
            left bottom 40px,
            right bottom 30px;
        }
      }
    }

    &_subs {
      padding-bottom: 80px;

      @media (max-width: 1080px) {
        .landing-page__services-item {
          margin-left: 36px;
        }
      }

      .subs-container {
        padding-top: 80px;

        background-image: url("./assets/bg-bottom@1x.png");
        background-repeat: no-repeat;
        background-size: 620px 413px;
        background-position: bottom -28px right;

        @media (max-width: 1270px) {
          background-size: 520px auto;
        }

        @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
          background-image: url("./assets/bg-bottom@2x.png");
        }
      }

      .landing-page__description {
        max-width: 460px;
      }

      .landing-page__services-item:first-child {
        margin-left: 0;
      }
    }
  }

  &__description {
    margin: 0;
    margin-top: 20px;

    text-align: center;
    letter-spacing: 0.5px;

    &_left {
      text-align: left;
    }
  }

  &__list {
    margin: 0;
    margin-top: 56px;
    padding: 0;

    list-style: none;
  }

  &__middle-button {
    margin-top: 60px;
  }

  &__clients-list {
    margin-left: auto;
    margin-right: auto;
    max-width: 1180px;

    display: flex;
    justify-content: space-between;
  }

  &__services-list {
    display: flex;
    align-items: center;

    max-width: 200%;

    &_wrap {
      flex-wrap: wrap;
      justify-content: center;

      max-width: 100%;
      padding: 0 20px;

      .landing-page__services-item {
        margin-bottom: 40px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__services-item {
    margin-left: 48px;

    &:last-child {
      margin-right: 48px;
    }
  }

  &__logos-wrapper {
    display: flex;

    margin-top: 120px;

    img {
      display: block;

      margin-right: 36px;
    }
  }

  &__reviews-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 48px;
  }

  &__reviews-item {
    &_with-padding {
      padding-top: 48px;
    }

    &_long {
      grid-row: span 2;
    }
  }

  &__subs-form {
    display: flex;
    justify-content: space-between;

    padding: 10px;
    margin-top: 60px;

    background-color: var(--landing-light);
    box-shadow: 0 0 26px rgba(219, 219, 219, 0.75);
    border-radius: 40px;

    input {
      width: 100%;
      padding: 0;
      padding-left: 24px;
      border: none;
      margin-right: 10px;

      line-height: 1em;

      background-color: transparent;
      border-radius: 60px 0 0 60px;

      &:focus {
        outline: none;
      }
    }
  }

  &__round-label {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    width: 60px;
    height: 60px;

    font-size: 14px;
    line-height: 1em;
    font-weight: 600;
    color: var(--landing-light);

    background-color: var(--landing-dark);
    border-radius: 50%;
  }

  &__demo-wrapper {
    position: relative;
  }

  &__demo-image {
    display: block;

    padding: 90px 0 30px;
    width: 100%;
    height: auto;
  }

  &__demo-list {
    position: absolute;
    top: 0;
    left: 0;

    list-style: none;

    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  &__demo-item {
    position: absolute;

    &_first {
      top: 80px;
      left: 20%;
    }

    &_second {
      top: 33.8%;
      left: -8px;
    }

    &_third {
      top: 39.6%;
      right: 56px;
    }

    &_fourth {
      bottom: 21%;
      right: -48px;
    }
  }
}

// Всякое прочее :с

.main-container {
  width: 100%;
  max-width: 1390px;
  min-width: 1024px;
  padding: 0 20px;
  margin: 0 auto;

  &_short {
    max-width: 1200px;
  }

  &_long {
    max-width: 1440px;
    padding: 0;
  }
}

.right-column {
  box-sizing: content-box;
  padding-left: 54%;
  max-width: 460px;
  width: 100%;

  &_short {
    max-width: 360px;
  }
}

.left-column {
  max-width: 540px;
}

.site-button {
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 21px 36px 19px;
  min-width: 216px;
  border: none;

  font-size: 18px;
  font-weight: 600;
  line-height: 1em;
  color: var(--landing-light);
  letter-spacing: 0.1px;

  background-color: var(--landing-dark);
  border-radius: 40px;

  transition: background-color, 0.25s;

  &:hover {
    color: var(--landing-light);
    background-color: var(--langing-secondary);
  }

  &--long {
    min-height: 55px;
    min-width: 395px;
  }
}

.clients-item {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 108px;
  height: 108px;

  background-color: var(--landing-light);
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
  border-radius: 50%;

  img {
    display: block;
  }

  &--text {
    font-size: 14px;
    line-height: 1em;

    background-color: transparent;
    box-shadow: none;
    border-radius: 0;

    p {
      margin: 0;
    }
  }
}

.price-bubble {
  position: relative;

  display: inline-block;

  padding: 26px;

  // text-align: center;
  color: var(--landing-light);

  background-color: var(--landing-dark);
  border-radius: 60px;

  &::after {
    content: "";

    position: absolute;
    bottom: calc(100% - 2px);
    left: 60px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 15px 9px;
    border-color: transparent transparent var(--landing-dark) transparent;
  }

  &__wrapper {
    margin: 0;

    display: flex;
    align-items: center;
  }

  &__value {
    display: inline-block;

    min-width: 216px;
    padding: 16px 36px 12px;

    font-size: 32px;
    line-height: 1em;
    font-weight: 600;
    color: var(--landing-dark);

    background-color: var(--landing-neutral);
    border-radius: 60px;
    box-shadow: 0 0 26px rgba(219, 219, 219, 0.4);
  }

  &__description {
    max-width: 220px;
    margin-left: 16px;

    text-align: left;
  }
}
