.dashboard-layout {
  position: relative;

  width: 1441px;
  border-right: 1px solid var(--color-gray);

  display: flex;

  &__container {
    width: 100%;
  }

  &__menu {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;

    height: 100vh;
  }
}
