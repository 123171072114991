.IPregistration-forms {
  &__divider {
    background-color: #0000001a;
  }

  &__docs-form {
    margin-top: 10px;
  }

  .passport-form__row {
    margin-bottom: 25px;
  }
}
