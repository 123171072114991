.site-datepicker-with-mask {
  position: relative;

  .ant-picker {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  .anticon {
    transform: translateY(-1px);
  }

  &_red {
    .ant-input-affix-wrapper {
      border-color: var(--color-red) !important;
    }
  }

  &_orange {
    .ant-input-affix-wrapper {
      border-color: var(--color-orange) !important;
    }
  }

  &__title {
    font-size: 13px;
    color: #000000;
  }
}
