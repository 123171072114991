.retry-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  &_centered {
    margin: 0 auto;
  }

  &__text {
    color: #000;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &__btn {
    border-radius: 10px;
  }
}
