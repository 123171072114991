.site-footer {
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.32);
  padding: 0 30px 40px 30px;
  background-color: #ffffff;

  &_inner {
    display: flex;
    position: static;
    margin: 0 auto;
    max-width: 1070px;
    padding: 15px;
    padding-left: 255px;
    background-color: transparent;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  &__link {
    color: inherit;
    margin-bottom: 10px;
    transition: 0.3s;

    &:hover {
      color: #40a9ff;
    }
  }
}
