.common-form {
  .site-form__radio {
    margin-top: 7px;
  }

  &__select-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  &__name-wrapper {
    white-space: normal;
  }

  &__client-info {
    margin-top: 4px;

    font-size: 11px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.4);

    &:last-child {
      margin-top: 0;
    }
  }

  &__highlight {
    color: var(--color-blue);
  }

  &__firstname {
    grid-row: 2 / 2;
  }

  &__middlename {
    grid-row: 3 / 3;
  }

  &__email {
    grid-column: span 2;

    &_short {
      grid-column: span 1;
    }
  }

  &__phone {
    grid-column: 2 / 2;
  }

  &__nalog-radio {
    position: relative;
  }
}
