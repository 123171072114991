.site-select {
  position: relative;

  .ant-select {
    width: 100%;
  }

  &__title {
    color: #262626;
    font-size: 13px;
    margin-bottom: 2px;
  }
}
