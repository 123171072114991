.entry-aside {
  width: 100%;
  max-width: 200px;
  padding-top: 8px;
  position: sticky;
  top: 85px;
  z-index: 1;

  &__additional-content {
    margin-top: 16px;
  }

  &__mobile-key-info {
    background: rgba(1, 1, 1, 0.04);
    padding: 13px;
    border-radius: 5px;
    text-align: center;
    margin-top: 15px;
  }

  &__mobile-key-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    font-family: "Gilroy", "Arial", sans-serif;
    border-radius: 15px;
  }

  &__mobile-key-text {
    font-size: 11px;
    line-height: 13px;
    margin-top: 7px;
  }

  &__mobile-key-button {
    margin-top: 10px;
  }
}
