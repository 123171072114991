.hh-marketplace-form {
  &__title {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }

  &__checkbox {
    margin: 8px 0;
  }
}
