.activity-option {
  display: flex;

  &__wrapper {
    width: 100%;

    border: 1px solid #f0f0f0;
    border-radius: 4px;
    min-height: 64px;
    position: relative;
    padding: 8px 40px;

    &_disabled {
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: not-allowed;
      }
    }

    &_removed {
      .activity-option__activity-title {
        color: var(--color-red2);
      }
    }

    &_new {
      .activity-option__activity-title {
        color: var(--color-green2);
      }
    }
  }

  &__star {
    width: 15px;
    height: 14px;
    background: url("./images/star.svg") no-repeat center / contain;
    position: absolute;
    left: 14px;
    top: 10px;

    cursor: pointer;

    &_filled {
      background: url("./images/star-filled.svg") no-repeat center / contain;
    }
  }

  &__remove-btn {
    cursor: pointer;

    margin-left: 9px;
    padding: 0;
    border: none;

    background-color: transparent;

    &:focus {
      outline: none;
    }

    svg {
      display: block;
    }
  }

  &__change {
    color: var(--color-primary-blue);
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }

  &__activity-code {
    font-weight: 500;
    font-size: 14px;
  }

  &__activity-title {
    font-size: 14px;
  }

  @media print {
    &__wrapper {
      border: none;
      padding: 0;
      min-height: auto;
      border-radius: 0;
      display: flex;
    }

    &__star,
    &__remove-btn {
      display: none;
    }

    &__activity-code {
      font-weight: 400;
      min-width: 60px;
    }
  }
}
