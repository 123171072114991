.error-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title-wrapper {
    display: grid;

    text-align: center;
  }

  &__title-text {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 700;
  }

  &__title-emoji {
    font-size: 36px;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
