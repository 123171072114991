.activity-form {
  position: relative;

  &__search-option {
    display: flex;
    align-items: flex-start;
    padding: 5px 0;
  }

  &__search-item-title {
    color: #000000;
    margin-left: 5px;
  }

  &__search-result {
    min-height: 40vh;
    max-height: 40vh;
    padding: 10px;
    width: 100%;
    overflow: hidden;
  }

  &__search-input {
    width: 100%;
  }

  &__modal {
    max-width: 1000px !important;
    width: 100% !important;

    .ant-modal-header {
      padding-top: 17px;
      border-bottom-width: 2px;
    }

    .ant-tree li .ant-tree-node-content-wrapper {
      height: auto !important;

      .ant-tree-title {
        white-space: normal !important;
      }
    }
  }

  &__OKVED-list {
    overflow: auto;
    max-height: 55vh;
    margin-top: 15px;
  }

  &__tip {
    background: #fff8d2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 12px 13px;
    margin-bottom: 10px;
    margin-top: 18px;
  }

  &__tip-star {
    width: 15px;
    height: 14px;
    transform: translateY(-1px);
    background: url("./images/star-filled.svg") no-repeat center / contain;
    margin-right: 12px;
  }

  &__tip-text {
    font-size: 14px;
    line-height: 1em;
  }

  &__selected-activity,
  &__additional-activities {
    margin-top: 18px;
  }

  &__print-button {
    margin-top: 20px;
  }

  .activity-option {
    margin-bottom: 7px;
  }

  @media print {
    &__list {
      padding: 1cm;

      * {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
          "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
        font-size: 10px;
      }
    }

    &__title,
    &__subtitle {
      font-weight: 700;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 14px;
    }

    &__subtitle {
      margin-bottom: 8px;
      font-size: 12px;
    }

    &__selected-activity,
    &__additional-activities {
      margin-top: 8px;
    }

    &__OKVED-btn,
    &__tip {
      display: none;
    }
  }
}
