.upload-page {
  min-width: auto;
  padding-bottom: 48px;
  p &__content-wrapper {
    display: grid;
    justify-items: start;
    gap: 24px;
  }

  &__name {
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 20px;
    line-height: 25px;
    font-weight: 800;
  }

  .site-form__dragger {
    // возможно, стоит везде это добавить
    overflow: hidden;
  }
}
