.fns-registration {
  &__new-request-modal {
    max-width: 808px !important;
    width: 100% !important;
  }

  &__search-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    max-width: 550px;
    margin-right: 12px;
  }
}
