.copy-to-clipboard-button {
  cursor: pointer;

  min-width: 32px;
  height: 32px;
  border: none;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  box-shadow: none;

  path {
    transition: fill 0.3s;
  }

  &:hover path {
    fill: var(--color-primary-blue);
  }

  &:focus {
    outline: none;
  }
}
