.common-company-form {
  &__company-name {
    grid-column: span 3;
  }

  &__company-site {
    grid-column: span 2;
  }

  .site-form__radio {
    margin-top: 7px;
  }
}
