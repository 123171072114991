.signing {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .ant-divider-horizontal {
    margin: 20px 0;
  }

  &__inner {
    background: #fff;
    max-width: 733px;
    width: 100%;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__files-list {
    width: 100%;
    display: grid;
    grid-gap: 10px;
  }

  &__certs {
    width: 100%;
    display: grid;
    grid-gap: 10px;
  }

  &__cert {
    cursor: pointer;
    &_selected {
      border-color: var(--color-primary-blue) !important;
      border-width: 2px !important;
    }
  }

  &__sign-btn {
    margin-top: 20px;
  }

  &__result {
    display: flex;
    align-items: center;
  }

  &__result-icon {
    svg path {
      fill: var(--color-green3);
    }
  }

  &__result-label {
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    color: var(--color-green3);
    margin-left: 10px;
  }
}
