.OOOregistration-forms {
  &__button-wrapper {
    position: relative;

    & + .OOOregistration-forms__owners-wrapper {
      margin-top: 7px;
    }
  }

  &__owner-section {
    margin-bottom: 7px;

    background-color: #f3f3f3;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__loader-icon {
    color: var(--color-primary-blue);
  }

  &__owner-status {
    display: flex;
    align-items: center;

    svg {
      margin-top: -2px;
      margin-right: 6px;
    }
  }

  &__signing {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__signing-wrapper {
    display: flex;
    align-items: center;

    padding-left: 14px;
    padding-right: 14px;
  }

  &__signing-link {
    display: inline-block;

    margin: 0;
    max-width: 528px;

    background-color: transparent;
  }

  &__docs-form {
    margin-top: 12px;
  }
}
