.passport-form {
  $root: &;

  &__passport-number {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &__kp {
    grid-row-start: 3;
  }

  &__get-source {
    grid-column: span 2;
    grid-row: span 2;

    textarea {
      margin-bottom: 0 !important;

      resize: none;
    }

    .site-textarea__error {
      margin-top: 0;
    }
  }

  &__pob {
    grid-column: span 2;

    &_hidden {
      display: none;
    }
  }

  &__inn-wrapper {
    grid-column: span 2;
    display: flex;
    align-items: flex-end;

    &_inostr {
      grid-column: span 1;
    }
  }

  &__inn-field {
    max-width: 176px;
  }

  &__inn-button {
    margin-bottom: 4px;
    margin-left: 8px;
  }

  .site-radio {
    position: relative;
    top: 4px;
  }

  &__gender {
    grid-column: span 2;

    .site-radio {
      top: 0;
    }
  }

  .ant-radio-button-wrapper {
    padding: 0 16px;
  }

  &_inostr {
    #{$root}__passport-number {
      grid-row-start: 2;
      grid-column-start: 2;
    }

    #{$root}__get-source {
      grid-column: 2/4;
      grid-row: 3/5;
    }

    #{$root}__inostr-lastname,
    #{$root}__inostr-firstname,
    #{$root}__inostr-middlename,
    #{$root}__citizenship {
      grid-column-start: 1;
    }

    #{$root}__citizenship {
      grid-row-start: 1;
    }

    #{$root}__passport-get-date {
      grid-row-start: 2;
      grid-column-start: 3;
    }
  }
}
