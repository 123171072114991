.site-datepicker {
  position: relative;

  .ant-picker {
    width: 100%;
  }

  &__title {
    margin-bottom: 2px;
    color: #262626;
    font-size: 14px;
  }

  &_red {
    .ant-picker {
      border-color: var(--color-red) !important;
    }
  }

  &_orange {
    .ant-picker {
      border-color: var(--color-orange) !important;
    }
  }
}
