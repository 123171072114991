.site-textarea {
  position: relative;

  &__title {
    margin-bottom: 2px;
    color: #000000;
    font-size: 13px;
  }

  &__error {
    margin-top: 2px;
    color: var(--color-red);
  }

  textarea {
    padding-top: 7px;
  }

  &_red {
    textarea {
      border-color: var(--color-red) !important;
    }
  }

  &_orange {
    textarea {
      border-color: var(--color-orange);
    }
  }
}
