.settings-container {
  display: flex;
  flex-direction: column;

  &__checkbox {
    margin-left: 0 !important;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__password-form {
    flex-direction: column;
    margin-top: 8px;
  }

  &__input {
    margin-bottom: 8px;
  }
}
