.company-name {
  padding: 6px;

  font-size: 12px;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;

  border-radius: 2px;
  background-color: #f3f3f3;
}
