.upload-link {
  cursor: pointer;
  border: none;
  padding: 0;
  display: inline-block;
  font-family: "Gilroy", "Arial", sans-serif;
  font-weight: 800;
  text-align: left;
  line-height: 1.2;
  color: inherit;
  transition-property: color, opacity;
  transition-duration: 0.3s;
  background-color: transparent;

  &_narrow {
    max-width: 140px;
  }

  &:hover {
    color: var(--color-blue);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
