.entry-page {
  max-width: 1070px;
  min-width: 1070px;
  margin: 0 auto;
  padding: 20px 15px 15px;

  position: relative;

  &__content-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__wrapper {
    width: 100%;
    max-width: 800px;
  }

  &__btn {
    background-color: var(--color-primary-blue);
    color: #fff;
    font-size: 12px;
    margin-right: 5px;
  }

  .activity-form__OKVED-btn {
    display: block;

    margin-top: 12px;
  }
}
