.site-header {
  $root: &;

  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 11;
  background: var(--color-gray2);

  &_outer {
    border-color: transparent;

    #{$root}__logo-link {
      display: none;
    }

    #{$root}__wrapper {
      width: auto;
      margin: 0;
      padding: 0;
      padding-right: 30px;

      justify-content: flex-end;
    }
  }

  &_fixed {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    background-color: transparent;
  }

  &__wrapper {
    margin: 0 auto;
    width: 1070px;
    padding: 0 15px;

    display: flex;
    align-items: center;
  }

  &__inner-content {
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: 170px;
    margin-right: 15px;
  }

  &__back {
    border: none;
    padding: 0;
    margin-right: 10px;

    color: #000000;
    line-height: 1em;

    transform: translateY(1px);

    background-color: transparent;
    box-shadow: none;

    &:focus,
    &:hover {
      color: #000000;
      background-color: transparent;
    }
  }

  &__controls-wrapper {
    display: flex;
    align-items: center;
  }

  &__save *,
  &__back *,
  &__username {
    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
  }

  &__save {
    border: none;
    margin-right: 0;

    color: #000;
    line-height: 1em;

    box-shadow: none;
    background-color: transparent;
  }

  &__dropdown {
    margin-top: 4px;
  }

  &__user-info {
    position: relative;
    padding-right: 20px;

    color: rgba(0, 0, 0, 0.33);
    display: flex;
    align-items: center;

    .ant-dropdown-link.ant-dropdown-trigger {
      display: flex;
      align-items: center;
    }

    .anticon.anticon-more {
      font-size: 20px;
    }
  }

  &__username {
    color: #000000;

    transform: translateY(1px);

    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &__link {
    line-height: 1.8em;
  }

  &__link:hover {
    #{$root}__username {
      color: var(--color-primary-blue);
    }

    #{$root}__arrow-icon {
      &::before,
      &::after {
        background-color: var(--color-primary-blue);
      }
    }
  }

  &__avatar {
    margin-right: 8px;
  }

  &__arrow-icon {
    position: absolute;
    top: calc(50% + 3px);
    right: 0;
    width: 10px;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 1.5px;
      background-color: #000000;
      border-radius: 2px;
      transition-property: transform, background-color;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &::before {
      transform: rotate(-48deg) translateX(3px);
    }

    &::after {
      transform: rotate(48deg) translateX(-3px);
    }

    &_opened {
      transform: translateY(-4px);

      &::before {
        transform: rotate(48deg) translate(3px, -0.5px);
      }

      &::after {
        transform: rotate(-48deg) translate(-3px, -0.5px);
      }
    }
  }

  &__balance {
    margin-right: 15px;
    margin-left: 7px;
    margin-top: 4px;

    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 22px;
    line-height: 1em;
    font-weight: 800;
    color: #000000;

    &_low {
      color: var(--color-red);
    }
  }

  &__pin-entry-modal {
    top: 96px !important;
    max-width: 500px !important;
  }

  &__pin-entry-modal-btn {
    margin-top: 10px;
  }

  &__pin-entry-modal-title {
    font-family: "Gilroy", "Arial", sans-serif;
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
  }
}
