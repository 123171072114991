.user-form {
  &__firstname {
    grid-row: 2 / 2;
  }

  &__middlename {
    grid-row: 3 / 3;
  }

  &__email {
    grid-column: span 2;
  }

  &__status-radio {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
  }

  &__radio-title {
    margin-top: 1px;
    margin-bottom: -1px;
  }

  &__upload-field {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }

  &__buttons-wrapper {
    margin-top: 16px;
    display: flex;
  }

  &__submit-button {
    margin-right: 8px;
  }
}
