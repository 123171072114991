.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  min-height: 100vh;

  &::after {
    content: "";
    background: #000000 url("./images/404.jpg") no-repeat center / contain;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  &__title,
  &__text,
  &__link {
    color: #ffffff;
  }

  &__title {
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 116px;
  }

  &__text {
    font-size: 16px;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color: rgba(#fff, 0.8);
    }
  }
}
